import gql from "graphql-tag";
import { print } from "graphql";
import { ImagePresetsFragment } from "../ImagePresetsFragment";

export const MoreCustomerExperiencesEntryBlockFragment = gql`
  fragment MoreCustomerExperiencesEntryBlockFragment on _prepr_types {
    ... on MoreCustomerExperiencesEntryBlock {
      title
      customer_stories {
        quote
        items {
          _slug
          caption
          quote
          image {
            ...ImagePresetsFragment
          }
        }
      }
      button_text
    }
  }
  ${print(ImagePresetsFragment)}
`;
