import { print } from "graphql";
import gql from "graphql-tag";
import { ButtonTargetFragment } from "./ButtonTargetFragment";

export const InformationTabsBlockFragment = gql`
  fragment InformationTabsBlockFragment on _prepr_types {
    ... on InformationTabsBlock {
      title
      description
      tabs {
        title
        content
        button_text
        button_target {
          ...ButtonTargetFragment
        }
      }
      button_text
      button_target {
        ...ButtonTargetFragment
      }
    }
  }
  ${print(ButtonTargetFragment)}
`;
