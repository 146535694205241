import React from "react";

type ChevronDownTwoProps = React.SVGProps<SVGSVGElement>;

const ChevronDownTwo: React.FC<ChevronDownTwoProps> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M20.7284 8.92648L12.5928 16.2899C12.2796 16.5695 11.81 16.5695 11.4965 16.2899L3.27049 8.92648C2.93505 8.57876 2.90794 8.10833 3.20964 7.77084C3.53076 7.41801 4.04825 7.42312 4.36682 7.70948L11.9997 14.582L19.6341 7.70948C19.9713 7.40896 20.4875 7.43534 20.7907 7.77022C21.0914 8.10833 21.0659 8.57876 20.7284 8.92648Z" />
    </svg>
  );
};

export default ChevronDownTwo;
