import { print } from "graphql";
import gql from "graphql-tag";
import { ImagePresetsFragment } from "../ImagePresetsFragment";
import { ButtonTargetFragment } from "./ButtonTargetFragment";

export const PageHeaderFragment = gql`
  fragment PageHeaderFragment on _prepr_types {
    ... on PageHeader {
      title
      subtitle
      description
      background_image {
        ...ImagePresetsFragment
      }
      show_circle
      action_cards {
        title
        body
        button_text
        image {
          ...ImagePresetsFragment
        }
        button_target {
          ...ButtonTargetFragment
        }
      }
    }
  }
  ${print(ImagePresetsFragment)}
  ${print(ButtonTargetFragment)}
`;
