import gql from "graphql-tag";

export const HeadingBlockFragment = gql`
  fragment HeadingBlockFragment on _prepr_types {
    ... on HeadingBlock {
      text
      type
    }
  }
`;
