import Image from "next/image";

const ContactBar = ({ icon, title }: { icon: any; title: string }) => (
  <div className="flex">
    <Image src={icon} alt={`${title} icon`} width="24" height="24"></Image>
    <span className="ml-4">{title}</span>
  </div>
);

export default ContactBar;
