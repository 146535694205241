import { parseText } from "@/utilities/htmlParser";
import { Grid } from "@/components/shared/Grid";
import { GetDynamicContentItemProps } from "@/components/blocks/DynamicContentBlock/types";
import { PageCardLinks } from "../shared/PageCardLinks/PageCardLinks";
import Cta from "../shared/Cta";

export type PageLinksBlockProps = GetDynamicContentItemProps<"PageLinksBlock">;

const PageLinksBlock: React.FC<PageLinksBlockProps> = ({
  title,
  subtitle,
  body,
  cards,
  cta_text,
  cta_image,
  button_text,
  button_target,
  className,
}) => {
  const ctaBlock = {
    text: cta_text,
    image: cta_image?.[0],
    buttonText: button_text,
  };

  if (!cards) return null;

  return (
    <Grid
      dataBlock="PageLinksBlock"
      className={`tablet:pb-20 relative overflow-hidden pb-10 ${className ?? ""}`}
    >
      {title && (
        <div className="text-intro text-secondary-aubergine tablet:col-span-9 tablet:col-start-2 col-span-full row-start-1 font-serif tracking-wider">
          {title}
        </div>
      )}
      {subtitle && (
        <h2 className="text-h2-sm tablet:col-span-4 tablet:col-start-2 tablet:mt-6 tablet:text-h3 col-span-full row-start-2 mt-4 font-bold tracking-wider">
          {subtitle}
        </h2>
      )}
      {body && (
        <div className="text-p-sm tablet:col-span-5 tablet:col-start-6 tablet:row-start-2 tablet:mt-6 tablet:pt-3 tablet:text-p col-span-full row-start-3 mt-6 font-serif tracking-wider">
          {parseText(body)}
        </div>
      )}
      <PageCardLinks cards={cards} className="tablet:mt-20 mt-10" />

      {ctaBlock?.text && button_target?.[0] && ctaBlock.buttonText && (
        <Cta
          text={ctaBlock.text}
          buttonText={ctaBlock.buttonText}
          buttonTarget={button_target?.[0]}
          image={ctaBlock.image}
          className="tablet:row-start-6 tablet:mt-20 relative z-10 row-start-5 mt-6"
        />
      )}
      <div className="bg-grey-light tablet:top-40 tablet:row-start-5 absolute bottom-0 top-20 z-0 row-span-2 row-start-4 h-full w-full" />
    </Grid>
  );
};

export default PageLinksBlock;
