import { print } from "graphql";
import gql from "graphql-tag";
import { ImagePresetsFragment } from "../ImagePresetsFragment";
import { ButtonTargetFragment } from "./ButtonTargetFragment";

export const PageLinksBlockFragment = gql`
  fragment PageLinksBlockFragment on _prepr_types {
    ... on PageLinksBlock {
      title
      subtitle
      body
      cards {
        title
        subtitle
        dark
        target {
          __typename
          ... on DialogForm {
            form_type
            salesforce_entity
            name
          }
          ... on ServiceRequestForm {
            _slug
          }
          ... on DynamicPage {
            _slug
          }
          ... on StaticPage {
            _slug
          }
          ... on ExternalPage {
            url
          }
          ... on FaqSubject {
            name
            topic {
              name
            }
          }
        }
        image {
          ...ImagePresetsFragment
        }
      }
      cta_image {
        ...ImagePresetsFragment
      }
      cta_text
      button_text
      button_target {
        ...ButtonTargetFragment
      }
    }
  }
  ${print(ImagePresetsFragment)}
  ${print(ButtonTargetFragment)}
`;
