import { print } from "graphql";
import gql from "graphql-tag";
import { ButtonTargetFragment } from "./ButtonTargetFragment";

export const TeamBlockFragment = gql`
  fragment TeamBlockFragment on _prepr_types {
    ... on TeamBlock {
      title
      body
      button_text
      button_target {
        ...ButtonTargetFragment
      }
      dark
      image {
        default: url(preset: "default")
        description
      }
    }
  }
  ${print(ButtonTargetFragment)}
`;
