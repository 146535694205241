import { print } from "graphql";
import gql from "graphql-tag";
import { ImagePresetsFragment } from "../ImagePresetsFragment";
import { ButtonTargetFragment } from "./ButtonTargetFragment";

export const CallToActionBlock2Fragment = gql`
  fragment CallToActionBlock2Fragment on _prepr_types {
    ... on CallToActionBlock2 {
      text
      image {
        default: url(preset: "default")
        url: url
        description
      }
      variant
      button_text
      button_target {
        ...ButtonTargetFragment
      }
    }
  }
  ${print(ImagePresetsFragment)}
  ${print(ButtonTargetFragment)}
`;
