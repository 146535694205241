import { print } from "graphql";
import gql from "graphql-tag";
import { ImagePresetsFragment } from "../ImagePresetsFragment";

export const ThreeImagesBlockFragment = gql`
  fragment ThreeImagesBlockFragment on _prepr_types {
    ... on ThreeImagesBlock {
      images {
        ...ImagePresetsFragment
      }
      description
    }
  }
  ${print(ImagePresetsFragment)}
`;
