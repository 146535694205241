import {
  LeaseCalculatorApiError,
  ObjectsAndGroups,
  getAllowedConstructionYears,
} from "@/services/lease-calculator/api-calls";

import { hasWindow } from "@/utilities/predicates/hasWindow";

export const getObjectsAndGroups = async (): Promise<ObjectsAndGroups> => {
  const fetchResult = await fetch("/api/lease-calculator/objects-and-groups");
  if (fetchResult.ok) return fetchResult.json();
  throw new Error("cannot fetch objects and groups");
};

export const getAllowedConstructionYearsViaQuery = async (objectId?: number) => {
  if (!objectId) return [];

  //waarom moet dit?
  if (!hasWindow()) {
    const result = await getAllowedConstructionYears(objectId);
    if (result instanceof LeaseCalculatorApiError)
      throw new Error("cannot fetch allowed-construction-years");
    return result;
  }

  const fetchResult = await fetch(
    `/api/lease-calculator/allowed-construction-years?object_id=${objectId}`
  );
  if (fetchResult.ok) return (await fetchResult.json()) as number[];
  throw new Error("cannot fetch allowed-construction-years");
};

export const getAllowedDurations = async (objectId?: number, constructionYear?: number) => {
  if (!objectId || !constructionYear || !Number(objectId) || !Number(constructionYear)) return [];

  const params = new URLSearchParams({
    object_id: objectId.toString(),
    construction_year: constructionYear.toString(),
  });
  const fetchResult = await fetch(`/api/lease-calculator/allowed-durations?${params.toString()}`);
  if (fetchResult.ok) return (await fetchResult.json()) as number[];
  throw new Error("cannot fetch allowed-durations");
};

export const getMaximumClosingTerm = async (
  objectId?: number,
  duration?: number,
  constructionYear?: number,
  purchasePrice?: number,
  constructionMonth?: number
) => {
  if (!objectId || !duration || !constructionYear || !purchasePrice) return Infinity;
  const params = new URLSearchParams({
    object_id: objectId.toString(),
    duration: duration.toString(),
    construction_year: constructionYear.toString(),
    purchase_price: purchasePrice.toString(),
    construction_month: constructionMonth ? constructionMonth.toString() : "",
  });
  const fetchResult = await fetch(
    `/api/lease-calculator/maximum-closing-term?${params.toString()}`
  );
  if (fetchResult.ok) return (await fetchResult.json()) as number;
  throw new Error("cannot fetch maximum-closing-term");
};

export const getMonthlyTerm = async (
  objectId?: number,
  duration?: number,
  constructionYear?: number,
  purchasePrice?: number,
  downPayment?: number,
  closingTerm?: number,
  constructionMonth?: number
) => {
  if (
    !objectId ||
    !duration ||
    !constructionYear ||
    !purchasePrice ||
    downPayment === undefined ||
    closingTerm === undefined
  )
    return 0;

  const params = new URLSearchParams({
    object_id: objectId.toString(),
    duration: duration.toString(),
    construction_year: constructionYear.toString(),
    purchase_price: purchasePrice.toString(),
    down_payment: downPayment.toString(),
    closing_term: closingTerm.toString(),
    construction_month: constructionMonth?.toString() || "",
  });
  const fetchResult = await fetch(`/api/lease-calculator/monthly-term?${params.toString()}`);
  if (fetchResult.ok) return (await fetchResult.json()) as number;
  const res = await fetchResult.json();
  if (res instanceof Array) {
    throw new Error("Failed checks:" + res.join(", "));
  }
  throw new Error("cannot fetch monthly-term");
};
