import { useFormContext, useController } from "react-hook-form";
import FormInput from "./FormInput";

interface Props {
  name: string;
  label: string;
  className?: string;
  disabled?: boolean;
  placeholder?: string;
  onBlur?: () => void;
}
export const ControlledFormInput: React.FC<Props> = ({
  name,
  label,
  className,
  disabled,
  placeholder,
  onBlur,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const {
    field,
    formState: { isLoading },
  } = useController({
    name,
    control,
    rules: { required: true },
  });
  return (
    <FormInput
      label={label}
      className={className}
      onChange={field.onChange}
      onBlur={() => {
        if (onBlur) onBlur();
        field.onBlur();
      }}
      value={field.value}
      name={field.name}
      placeholder={placeholder}
      inputRef={field.ref}
      disabled={isLoading || disabled}
      error={errors[name]?.message ? <>{errors[name]?.message}</> : ""}
    />
  );
};
