import { print } from "graphql";
import gql from "graphql-tag";
import { ImagePresetsFragment } from "../ImagePresetsFragment";

export const SubHeaderLinkCardsBlockFragment = gql`
  fragment SubHeaderLinkCardsBlockFragment on _prepr_types {
    ... on SubHeaderLinkCardsBlock {
      cards {
        title
        subtitle
        dark
        target {
          __typename
          ... on DialogForm {
            form_type
            salesforce_entity
            name
          }
          ... on ServiceRequestForm {
            _slug
          }
          ... on DynamicPage {
            _slug
          }
          ... on StaticPage {
            _slug
          }
          ... on ExternalPage {
            url
          }
          ... on FaqSubject {
            name
            topic {
              name
            }
          }
        }
        image {
          ...ImagePresetsFragment
        }
      }
    }
  }
  ${print(ImagePresetsFragment)}
`;
