import gql from "graphql-tag";

export const FaqOverviewBlockFragment = gql`
  fragment FaqOverviewBlockFragment on _prepr_types {
    ... on FaqOverviewBlock {
      category_heading
      subcategory_heading
      items_heading
      faq_subjects {
        name
        # alias to make this property the same as getServiceSubcategories
        category: topic {
          name
        }
        # alias to make this property the same as getServiceSubcategories
        accordion_items: faq_items {
          question
          answer
          button_text
          button_target {
            __typename
            ... on DialogForm {
              form_type
              salesforce_entity
              name
            }
            ... on ServiceRequestForm {
              _slug
            }
            ... on DynamicPage {
              _slug
            }
            ... on StaticPage {
              _slug
            }
            ... on ExternalPage {
              url
            }
          }
        }
      }
    }
  }
`;
