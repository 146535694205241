import { print } from "graphql";
import gql from "graphql-tag";
import { ButtonTargetFragment } from "./ButtonTargetFragment";

export const BlogsEntryBlockFragment = gql`
  fragment BlogsEntryBlockFragment on _prepr_types {
    ... on BlogsEntryBlock {
      title
      blogs {
        _slug
        type
        card_title
        _publish_on
        image {
          ... on Asset {
            default: url(preset: "default")
            description
          }
        }
      }
      cta_text
      button_text
      button_target {
        ...ButtonTargetFragment
      }
    }
  }
  ${print(ButtonTargetFragment)}
`;
