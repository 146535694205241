import { useFormContext } from "react-hook-form";
import { Card, CardTitle } from "@hiltermann/components/dist/ui";
import clsx from "clsx";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { ControlledFormInput } from "@/components/shared/Forms/ControlledFormInput";
import { ObjectsAndGroups } from "@/services/lease-calculator/api-calls";
import { ControlledSelectList } from "@/components/shared/Forms/ControlledSelectList";
import { parseText } from "@/utilities/htmlParser";
import { getAllowedConstructionYearsViaQuery } from "../Queries";
import { useAddApiError, useAddApiLoading } from "../LeaseCalculatorStateProvider";
import { filterMonthOption } from "../utils";

interface ObjectGegevensProps {
  className?: string;
  objectsAndGroups: ObjectsAndGroups;
  isLoading: boolean;
  title?: string | null;
  description?: string | null;
}

type monthOptionsType = { [key: number]: string };
export const monthOptions: monthOptionsType = {
  1: "Januari",
  2: "Februari",
  3: "Maart",
  4: "April",
  5: "Mei",
  6: "Juni",
  7: "Juli",
  8: "Augustus",
  9: "September",
  10: "Oktober",
  11: "November",
  12: "December",
};

export const ObjectGegevens: React.FC<ObjectGegevensProps> = ({
  className,
  objectsAndGroups,
  isLoading,
  title,
  description,
}) => {
  const { watch, setValue } = useFormContext();
  const objectGroupId = watch("objectGroupId");
  const objectId = watch("objectId");
  const constructionYear = watch("constructionYear");
  const duration = watch("duration");

  const groupOptions = objectsAndGroups.objectGroups.reduce(
    (acc, item) => ((acc[item.objectGroupId] = item.name), acc),
    {} as Record<number, string>
  );

  const objectOptions = objectsAndGroups.objects
    .filter((o) => o.objectGroupId == objectGroupId)
    .reduce((acc, item) => ((acc[item.objectId] = item.name), acc), {} as Record<number, string>);
  useEffect(() => {
    if (objectId && objectOptions[objectId] === undefined) setValue("objectId", "");
  }, [objectGroupId, objectId, setValue]);

  const constructionYears = useQuery(
    ["getAllowedConstructionYears", objectId],
    () => getAllowedConstructionYearsViaQuery(objectId),
    { retry: false }
  );

  useAddApiError("durations", constructionYears.isError);
  useAddApiLoading("duration", constructionYears.isLoading);

  const constructionYearOptions = constructionYears.data
    ? constructionYears.data.reduce(
        (acc, item) => ((acc[item] = item.toString()), acc),
        {} as Record<number, string>
      )
    : {};

  const leaseStartMaxAge =
    objectsAndGroups.objects.find((o) => o.objectId == objectId)?.leaseStartMaxAge || Infinity;
  const leaseEndMaxAge =
    objectsAndGroups.objects.find((o) => o.objectId == objectId)?.leaseEndMaxAge || Infinity;

  const filteredMonthOptions: monthOptionsType = Object.keys(monthOptions)
    .filter((key) =>
      filterMonthOption(Number(key), constructionYear, duration, leaseStartMaxAge, leaseEndMaxAge)
    )
    .reduce((obj, key) => {
      return Object.assign(obj, { [key]: monthOptions[Number(key)] });
    }, {});

  const isVehicle = objectId
    ? objectsAndGroups.objects.find((o) => o.objectId == objectId)?.isVehicle || false
    : false;

  useEffect(() => {
    setValue("isVehicle", isVehicle);
    if (isVehicle) {
      setValue("operatinghours", undefined);
    } else {
      setValue("licenseplate", undefined);
      setValue("mileage", undefined);
      setValue("constructionMonth", "");
    }
  }, [isVehicle, setValue]);

  return (
    <Card variant="white" className={clsx("rounded-2xl", "p-8", className)}>
      <CardTitle>{title}</CardTitle>
      {description && (
        <div className="text-p-sm desktop-s:text-p mt-1 font-serif tracking-wider">
          {parseText(description)}
        </div>
      )}
      <div className="tablet:mt-8 tablet:grid-cols-2 tablet:gap-6 mt-4 grid gap-4">
        <ControlledSelectList
          name="objectGroupId"
          label="Objectgroep *"
          onChange={() => {
            setValue("duration", undefined);
            setValue("constructionYear", "");
            setValue("constructionMonth", "");
          }}
          placeholder="maak een keuze..."
          options={groupOptions}
          order="ascending"
          disabled={isLoading}
        />
        <ControlledSelectList
          name="objectId"
          label="Object *"
          placeholder={objectGroupId ? "maak een keuze..." : "kies eerst een objectgroep..."}
          onChange={() => {
            setValue("duration", undefined);
            setValue("constructionYear", "");
            setValue("constructionMonth", "");
          }}
          options={objectOptions}
          disabled={!objectGroupId || isLoading}
          order="ascending"
        />
        <ControlledFormInput label="Merk *" name="brand" disabled={isLoading} />
        <ControlledFormInput label="Model *" name="model" disabled={isLoading} />
        <ControlledSelectList
          name="constructionYear"
          label="Bouwjaar *"
          placeholder={
            constructionYears.isError
              ? "ERROR: kan bouwjaren niet inladen"
              : constructionYears.isLoading
              ? "mogelijke bouwjaren worden ingeladen..."
              : objectId
              ? "maak een keuze..."
              : "kies eerst een object..."
          }
          options={constructionYearOptions}
          order="ascending"
          onChange={() => {
            setValue("duration", undefined);
            setValue("constructionMonth", "");
          }}
          disabled={
            !objectId || constructionYears.isLoading || constructionYears.isError || isLoading
          }
        />
        {isVehicle && (
          <ControlledSelectList
            name="constructionMonth"
            label="Bouwmaand"
            placeholder={
              constructionYears.isError
                ? "ERROR: kan bouwjaren niet inladen"
                : constructionYears.isLoading
                ? "mogelijke bouwjaren worden ingeladen..."
                : constructionYear
                ? "maak een keuze..."
                : objectId
                ? "kies eerst een bouwjaar..."
                : "kies eerst een object..."
            }
            options={filteredMonthOptions}
            order="key"
            disabled={
              !constructionYear ||
              !objectId ||
              constructionYears.isLoading ||
              constructionYears.isError ||
              isLoading
            }
          />
        )}
        {isVehicle && <ControlledFormInput label="Kenteken" name="licenseplate" />}
        {isVehicle && <ControlledFormInput label="Kilometerstand" name="mileage" />}
        {!isVehicle && <ControlledFormInput label="Urenstand" name="operatinghours" />}
      </div>
    </Card>
  );
};
