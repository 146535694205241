import { print } from "graphql";
import gql from "graphql-tag";
import { ImagePresetsFragment } from "../ImagePresetsFragment";
import { ButtonTargetFragment } from "./ButtonTargetFragment";

export const FaqBlockFragment = gql`
  fragment FaqBlockFragment on _prepr_types {
    ... on FaqBlock {
      title
      faq_items {
        question
        answer
      }
      faq_button_text
      faq_button_target {
        ...ButtonTargetFragment
      }
      contact_title
      contact_body
      contact_button_text
      contact_button_target {
        ...ButtonTargetFragment
      }
      image {
        ...ImagePresetsFragment
      }
    }
  }
  ${print(ImagePresetsFragment)}
  ${print(ButtonTargetFragment)}
`;
