import gql from "graphql-tag";

export const HighlightsBlockFragment = gql`
  fragment HighlightsBlockFragment on _prepr_types {
    ... on HighlightsBlock {
      title
      highlights {
        title
        description
        icon {
          url
          description
        }
      }
      dark_theme_enabled
    }
  }
`;
