import Image from "next/image";
import React from "react";
import useHoverState from "@/hooks/useHoverState";
import { Grid } from "@/components/shared/Grid";
import { SubjectOverviewTarget } from "../SubjectOverviewBlock";
import SubjectCard from "./SubjectCard";

interface SubjectMobileProps {
  title: string;
  body: string;
  target?: SubjectOverviewTarget;
  image?: {
    default?: string | null;
    description?: string | null;
  } | null;
}

const SubjectMobile: React.FC<SubjectMobileProps> = ({ title, body, image, target }) => {
  const [isHovered, setIsHovered] = useHoverState();

  return (
    <>
      {image?.default && (
        <div className={`relative mt-8 aspect-[4/3] min-h-[200px] w-full first:mt-0`}>
          <Image
            src={image.default}
            layout="fill"
            objectFit="cover"
            alt={image.description ?? `${title} image`}
          />
        </div>
      )}
      <Grid>
        <SubjectCard
          title={title}
          body={body}
          target={target}
          isHovered={isHovered}
          setIsHovered={setIsHovered}
          className={`shadow-card z-10 col-span-full -mt-5 bg-white px-6 py-8`}
        />
      </Grid>
    </>
  );
};

export default SubjectMobile;
