import gql from "graphql-tag";

export const QuoteBlockFragment = gql`
  fragment QuoteBlockFragment on _prepr_types {
    ... on QuoteBlock {
      html_text
      quote_source
    }
  }
`;
