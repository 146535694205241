import { print } from "graphql";
import gql from "graphql-tag";
import { ImagePresetsFragment } from "../ImagePresetsFragment";

export const OneImageBlockFragment = gql`
  fragment OneImageBlockFragment on _prepr_types {
    ... on OneImageBlock {
      image {
        ...ImagePresetsFragment
      }
      description
    }
  }
  ${print(ImagePresetsFragment)}
`;
