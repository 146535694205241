import React from "react";
import Image from "next/image";
import Link from "next/link";
import { Grid } from "@/components/shared/Grid";
import { GetDynamicContentItemProps } from "@/components/blocks/DynamicContentBlock/types";

export type IconsBlockProps = GetDynamicContentItemProps<"IconsBlock">;

const IconsBlock: React.FC<IconsBlockProps> = ({ icons, className }) => {
  return (
    <Grid dataBlock="IconsBlock" className={`relative ${className ?? ""}`}>
      <div className="tablet:col-span-8 tablet:col-start-3 tablet:grid tablet:grid-cols-3 col-span-4 gap-10">
        {icons?.map((icon, i) => {
          if (!icon.image) return null;
          return (
            <Link key={i} href={icon.button_target[0]?.url ?? ""}>
              <a target="_blank">
                <div className={`relative my-8 flex aspect-[35/18] justify-center align-middle`}>
                  <Image
                    data-testid="icon-icons-block"
                    src={icon.image[0]?.default ?? ""}
                    alt={icon.image[0]?.description ?? ""}
                    layout="fill"
                    objectFit="contain"
                    className="cursor-pointer"
                  />
                </div>
              </a>
            </Link>
          );
        })}
      </div>
    </Grid>
  );
};

export default IconsBlock;
