import Link from "next/link";
import React, { MouseEventHandler } from "react";
import { ButtonTarget, useButtonTarget } from "@/hooks/useButtonTarget";

interface PageCardWrapperProps {
  target: ButtonTarget;
  setIsHovered: {
    onMouseEnter: MouseEventHandler<any>;
    onMouseLeave: MouseEventHandler<any>;
  };
  children?: React.ReactNode;
  className?: string;
}

const PageCardWrapper: React.FC<PageCardWrapperProps> = ({
  target,
  setIsHovered,
  children,
  className,
}) => {
  const { href, onClick, type, isValid } = useButtonTarget(target);
  if (!isValid) return null;
  if (type === "button") {
    return (
      <button {...setIsHovered} onClick={onClick} className={`flex text-left ${className ?? ""}`}>
        {children}
      </button>
    );
  }
  if (type === "link") {
    return (
      <Link href={href || "/404"}>
        <a {...setIsHovered} className={className}>
          {children}
        </a>
      </Link>
    );
  }
  if (type === "externalLink" && href) {
    return (
      <a
        {...setIsHovered}
        href={href.startsWith("http") ? href : `//${href}`}
        className={className}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    );
  }
  return null;
};

export default PageCardWrapper;
