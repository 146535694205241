import { print } from "graphql";
import gql from "graphql-tag";
import { ImagePresetsFragment } from "../ImagePresetsFragment";

export const StepByStepBlockFragment = gql`
  fragment StepByStepBlockFragment on _prepr_types {
    ... on StepByStepBlock {
      title
      image {
        ...ImagePresetsFragment
      }
      steps {
        number
        title
        description
      }
    }
  }
  ${print(ImagePresetsFragment)}
`;
