import gql from "graphql-tag";
import { print } from "graphql";
import { ImagePresetsFragment } from "../ImagePresetsFragment";
import { ButtonTargetFragment } from "./ButtonTargetFragment";

export const CustomerExperienceCarouselFragment = gql`
  fragment CustomerExperienceCarouselFragment on _prepr_types {
    ... on CustomerExperienceCarouselBlock {
      title
      body
      customer_stories {
        quote
        items {
          _slug
          caption
          quote
          image {
            ...ImagePresetsFragment
          }
        }
      }
      card_button_text
      button_text
      button_target {
        ...ButtonTargetFragment
      }
    }
  }
  ${print(ImagePresetsFragment)}
  ${print(ButtonTargetFragment)}
`;
