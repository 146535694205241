import Image from "next/image";
import React from "react";
import useHoverState from "@/hooks/useHoverState";
import { SubjectOverviewTarget } from "../SubjectOverviewBlock";
import SubjectCard from "./SubjectCard";

interface SubjectDesktopProps {
  index: number;
  title: string;
  body: string;
  target?: SubjectOverviewTarget;
  image?: {
    default?: string | null;
    description?: string | null;
  } | null;
}

const SubjectDesktop: React.FC<SubjectDesktopProps> = ({ index, title, body, image, target }) => {
  const [isHovered, setIsHovered] = useHoverState();

  const imageColStart = index % 2 ? "col-start-6" : "col-start-2";
  const cardColStart = index % 2 ? "col-start-2" : "col-start-7";
  const gridRowStart = index + 1;
  const style = { gridRowStart, zIndex: index + 1 };

  return (
    <>
      {image?.default && (
        <div
          className={`relative col-span-6 -mt-5 aspect-[4/3] min-h-[200px] self-center overflow-hidden ${imageColStart}`}
          style={{ gridRowStart, zIndex: index }}
        >
          <Image
            src={image.default}
            layout="fill"
            objectFit="cover"
            alt={image.description ?? `${title} image`}
            className={`transition-1 ${isHovered ? "scale-105" : "scale-100"}`}
          />
        </div>
      )}
      <SubjectCard
        title={title}
        body={body}
        target={target}
        isHovered={isHovered}
        setIsHovered={setIsHovered}
        className={`shadow-card col-span-5 -mt-5 self-center bg-white p-12 ${cardColStart}`}
        style={style}
      />
    </>
  );
};

export default SubjectDesktop;
