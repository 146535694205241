import Image from "next/image";
import CardIconButton from "@/components/shared/CardIconButton";
import useHoverState from "@/hooks/useHoverState";
import { ButtonTarget } from "@/hooks/useButtonTarget";
import { Maybe } from "@/services/prepr/types_codegen";
import PageCardWrapper from "./PageCard/PageCardWrapper";

interface Props {
  title: string;
  subtitle?: Maybe<string>;
  image: {
    desktop?: Maybe<string>;
    mobile?: Maybe<string>;
    description?: Maybe<string>;
  };
  target: ButtonTarget;
}

const PageCardWithImage: React.FC<Props> = ({ title, subtitle, image, target }) => {
  const [isHovered, setIsHovered] = useHoverState();
  return (
    <PageCardWrapper
      target={target}
      setIsHovered={setIsHovered}
      className="tablet:aspect-[3/2] relative aspect-[3/1] cursor-pointer shadow-md"
    >
      {image.desktop && (
        <div className="tablet:block hidden">
          <Image
            src={image.desktop}
            alt={image.description ?? "card image"}
            layout="fill"
            objectFit="cover"
            className={`transition-1 ${isHovered ? "scale-105" : "scale-100"}`}
          />
        </div>
      )}
      {image.mobile && (
        <div className="tablet:hidden block">
          <Image
            src={image.mobile}
            alt={image.description ?? "card image"}
            layout="fill"
            objectFit="cover"
            className={`transition-1 ${isHovered ? "scale-105" : "scale-100"}`}
          />
        </div>
      )}
      <div className="bg-primary-black absolute z-10 h-full w-full opacity-50"></div>
      <div className="tablet:p-8 absolute z-20 flex h-full w-full p-6 opacity-100">
        <div className="desktop-s:gap-10 flex flex-1 items-end justify-between gap-4">
          <div>
            <div className="text-button-sm tablet:text-forms tablet:line-clamp-none line-clamp-2 font-bold text-white">
              {title}
            </div>
            {subtitle && (
              <div className="text-button-sm tablet:text-forms text-white">{subtitle}</div>
            )}
          </div>
          <CardIconButton isHovered={isHovered} whiteArrow={true} />
        </div>
      </div>
    </PageCardWrapper>
  );
};

export default PageCardWithImage;
