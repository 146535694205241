import gql from "graphql-tag";
import { print } from "graphql";
import { ImagePresetsFragment } from "../ImagePresetsFragment";

export const WhitepaperRequestBlockFragment = gql`
  fragment WhitepaperRequestBlockFragment on _prepr_types {
    ... on WhitepaperRequestBlock {
      _id
      whitepaper {
        _id
        _slug
        _environment_id
        title
        card_title
        bodytext
        whitepaperfile {
          url
        }
      }
      personimage {
        ...ImagePresetsFragment
      }
      titleoverwrite
      bodyoverwrite
      buttontext
    }
  }
  ${print(ImagePresetsFragment)}
`;
