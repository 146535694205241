import { SentryLogger } from "@/utilities/logger";
import { getFromNodeCache, setNodeCache } from "../nodeMemoryCache";

interface LeaseCalculatorObject {
  id_objecttype: number;
  description: string;
  insurance_code: "1geel" | "1grijs" | "2";
  id_objecttype_group: number;
  is_internal: boolean;
  max_age_end_contract: number;
  max_age_start_usage: number;
}

interface LeaseCalculatorObjectGroup {
  id_objecttype_group: number;
  name: string;
}

export type ObjectsAndGroups = {
  objects: ObjectType[];
  objectGroups: ObjectGroup[];
};

type ObjectType = {
  objectId: number;
  name: string;
  objectGroupId: number;
  isVehicle: boolean;
  leaseEndMaxAge: number;
  leaseStartMaxAge: number;
};

type ObjectGroup = {
  objectGroupId: number;
  name: string;
};

const ApiParams = {
  applicantId: 930,
  applicantCommission: 0,
  commisionCode: "A",
  noAdminCost: true,
  noPenaltyInterest: true,
  baseUrl: "https://frames.autoleasecompany.nl/command",
};

export class LeaseCalculatorApiError {
  public error: unknown;
  public constructor(error: unknown) {
    this.error = error;
  }
}
const currentMonth = new Date().getMonth() + 1;

const callApi = async <T>(
  url: string,
  cacheSeconds: number = 60
): Promise<T | LeaseCalculatorApiError> => {
  const cacheKey = `lease-calculator:${url}`;
  try {
    if (cacheSeconds > 0) {
      const fromCache = getFromNodeCache<T>(cacheKey);
      if (fromCache) return fromCache;
    }
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const json = await response.json();
    if (cacheSeconds > 0) {
      setNodeCache(cacheKey, json, cacheSeconds);
    }
    return json as T;
  } catch (error) {
    // TODO add full api-url to log
    SentryLogger.logError(error);
    return new LeaseCalculatorApiError(error);
  }
};

export const getObjectsAndGroups = async (): Promise<
  ObjectsAndGroups | LeaseCalculatorApiError
> => {
  const objects = await getObjects();
  if (objects instanceof LeaseCalculatorApiError) return objects;
  const objectGroups = await getObjectGroups(objects);
  if (objectGroups instanceof LeaseCalculatorApiError) return objectGroups;
  return { objects: objects, objectGroups: objectGroups };
};

export const getObjects = async (): Promise<ObjectType[] | LeaseCalculatorApiError> => {
  const params = new URLSearchParams({
    id_applicant: ApiParams.applicantId.toString(),
  });
  const url = `${ApiParams.baseUrl}/objecttype-byapplicantid?${params.toString()}`;
  const response = await callApi<{ data: LeaseCalculatorObject[] }>(url, 4 * 60 * 60);
  if (response instanceof LeaseCalculatorApiError) return response;
  const data = response.data;
  const objects = data
    .filter((object) => !object.is_internal)
    .map(
      (objectType): ObjectType => ({
        objectId: objectType.id_objecttype,
        name: objectType.description,
        objectGroupId: objectType.id_objecttype_group,
        isVehicle: objectType.insurance_code === "1geel" || objectType.insurance_code === "1grijs",
        leaseEndMaxAge: objectType.max_age_end_contract,
        leaseStartMaxAge: objectType.max_age_start_usage,
      })
    );
  return objects;
};

const getObjectGroups = async (
  objects: ObjectType[]
): Promise<ObjectGroup[] | LeaseCalculatorApiError> => {
  const url = `${ApiParams.baseUrl}/objecttypegroups`;
  const response = await callApi<LeaseCalculatorObjectGroup[]>(url, 4 * 60 * 60);
  if (response instanceof LeaseCalculatorApiError) return response;
  const objectGroups = response.map(
    (objectGroup): ObjectGroup => ({
      objectGroupId: objectGroup.id_objecttype_group,
      name: objectGroup.name,
    })
  );
  const relevantObjectGroupIds = objects.map((object) => object.objectGroupId).flat();
  const relevantObjectGroups = objectGroups.filter((objectGroup) =>
    relevantObjectGroupIds.includes(objectGroup.objectGroupId)
  );
  return relevantObjectGroups;
};

export const getAllObjectGroups = async (): Promise<
  LeaseCalculatorObjectGroup[] | LeaseCalculatorApiError
> => {
  const url = `${ApiParams.baseUrl}/objecttypegroups`;
  return callApi<LeaseCalculatorObjectGroup[]>(url, 4 * 60 * 60);
};

export const getObjectsByGroup = async (
  objectGroupId: number
): Promise<LeaseCalculatorObject[] | LeaseCalculatorApiError> => {
  const url = `${ApiParams.baseUrl}/objecttype?id_objecttype_group=${objectGroupId}`;
  const response = await callApi<{ data: LeaseCalculatorObject[] }>(url, 4 * 60 * 60);
  return response instanceof LeaseCalculatorApiError ? response : response.data;
};

export const getAllowedConstructionYears = async (
  objectId: number
): Promise<number[] | LeaseCalculatorApiError> => {
  const url = `${ApiParams.baseUrl}/allowedconstructionyears?id_objecttype=${objectId}`;
  return callApi<number[]>(url); //array of allowed years [2016,2015,2014]
};

export const getAllowedDurations = (
  objectId: number,
  constructionYear: number
): Promise<number[] | LeaseCalculatorApiError> => {
  const params = new URLSearchParams({
    id_objecttype: objectId.toString(),
    construction_year: constructionYear.toString(),
    id_applicant: ApiParams.applicantId.toString(),
  });
  const url = `${ApiParams.baseUrl}/alloweddurations?${params.toString()}`;
  const allowedDurations = callApi<number[]>(url); //array of allowed durations [6,12,24]
  return allowedDurations;
};

export const getMaximumClosingTerm = async (
  objectId: number,
  duration: number,
  constructionYear: number,
  purchasePrice: number,
  constructionMonth?: number
): Promise<number | LeaseCalculatorApiError> => {
  const params = new URLSearchParams({
    duration: duration.toString(),
    construction_year: constructionYear.toString(),
    construction_month: (constructionMonth ?? currentMonth).toString(),
    purchase_price: (purchasePrice / 100).toString(),
    id_objecttype: objectId.toString(),
    id_applicant: ApiParams.applicantId.toString(),
  });
  const url = `${ApiParams.baseUrl}/maximumclosingterm?${params.toString()}`;
  const response = await callApi<{ maxClosingTerm: string }>(url);
  return response instanceof LeaseCalculatorApiError
    ? response
    : convertToCents(response.maxClosingTerm);
};

export const calculateMonthlyTerm = async (
  objectId: number,
  duration: number,
  constructionYear: number,
  purchasePrice: number,
  downPayment: number,
  closingTerm: number,
  constructionMonth?: number
): Promise<number | string[] | LeaseCalculatorApiError> => {
  const params = new URLSearchParams({
    id_objecttype: objectId.toString(),
    id_applicant: ApiParams.applicantId.toString(),
    duration: duration.toString(),
    commissionCode: ApiParams.commisionCode,
    purchase_price: (purchasePrice / 100).toString(),
    down_payment: (downPayment / 100).toString(),
    commission: ApiParams.applicantCommission.toString(),
    closingterm: (closingTerm / 100).toString(),
    construction_year: constructionYear.toString(),
    construction_month: (constructionMonth ?? currentMonth).toString(),
    no_admin_cost: ApiParams.noAdminCost.toString(),
    no_penalty_interest: ApiParams.noPenaltyInterest.toString(),
  });
  const url = `${ApiParams.baseUrl}/calculatemonthlyterm?${params.toString()}`;
  const response = await callApi<{ monthly_term: string; checks: string[] }>(url); //example return: {"monthlyTermWithSurcharge":702.4873415519534,"checks":[],"adminCostCommissionSubtraction":0.0,"finalCommissionEuro":729.2225302979546,"noPenaltySurchargePercentage":0.0,"monthly_term":702.4873415519534,"monthly_terms":{"48":557.488406543566,"36":702.4873415519534,"24":993.9215306870647,"72":413.9117024298697,"12":1871.110964106739,"60":471.05961739579504},"commissionEuro":729.2225302979546}
  if (response instanceof LeaseCalculatorApiError) return response;
  if (response.checks.length == 0) return convertToCents(response.monthly_term.toString());
  return response.checks;
};

const convertToCents = (price: string): number => {
  const numericPrice = price.replace(/[^0-9.-]+/g, "");
  const cents = Math.round(parseFloat(numericPrice) * 100);
  return cents;
};
