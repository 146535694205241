import { print } from "graphql";
import gql from "graphql-tag";
import { ImagePresetsFragment } from "../ImagePresetsFragment";

export const ButtonBlockFragment = gql`
  fragment ButtonBlockFragment on _prepr_types {
    ... on ButtonBlock {
      text
      target {
        __typename
        ... on DialogForm {
          form_type
          salesforce_entity
          name
        }
        ... on ServiceRequestForm {
          _slug
        }
        ... on DynamicPage {
          _slug
        }
        ... on StaticPage {
          _slug
        }
        ... on ExternalPage {
          url
        }
        ... on RequestAppointmentForm {
          title
          introtext
          image {
            ...ImagePresetsFragment
          }
          confirmationtext
          campaigntag
        }
      }
      variant
    }
  }
  ${print(ImagePresetsFragment)}
`;
