import { print } from "graphql";
import gql from "graphql-tag";
import { ImagePresetsFragment } from "../ImagePresetsFragment";
import { ButtonTargetFragment } from "./ButtonTargetFragment";

export const PageHeaderTwoFragment = gql`
  fragment PageHeaderTwoFragment on _prepr_types {
    ... on PageHeaderTwo {
      title
      subtitle
      body
      tagline
      button_text
      button_target {
        ...ButtonTargetFragment
      }
      textlink_text
      textlink_target {
        ...ButtonTargetFragment
      }
      image {
        ...ImagePresetsFragment
      }
      circle_enabled
    }
  }
  ${print(ImagePresetsFragment)}
  ${print(ButtonTargetFragment)}
`;
