import { print } from "graphql";
import gql from "graphql-tag";
import { ImagePresetsFragment } from "../ImagePresetsFragment";

export const CustomerExperienceEntryBlockFragment = gql`
  fragment CustomerExperienceEntryBlockFragment on _prepr_types {
    ... on CustomerExperienceEntryBlock {
      title
      body
      darkthemeenabled
      ctabuttontext
      ctabuttontarget {
        __typename
        ... on DialogForm {
          form_type
          salesforce_entity
          name
        }
        ... on ServiceRequestForm {
          _slug
        }
        ... on DynamicPage {
          _slug
        }
        ... on StaticPage {
          _slug
        }
        ... on ExternalPage {
          url
        }
      }
      customquote
      customer_story {
        _slug
        caption
        quote
        image {
          ...ImagePresetsFragment
        }
      }
    }
  }
  ${print(ImagePresetsFragment)}
`;
