// import { print } from "graphql";
import { print } from "graphql";
import gql from "graphql-tag";
import { ImagePresetsFragment } from "../ImagePresetsFragment";
import { ButtonTargetFragment } from "./ButtonTargetFragment";

export const ContactPersonBlockFragment = gql`
  fragment ContactPersonBlockFragment on _prepr_types {
    ... on ContactPersonBlock {
      heading
      eyebrow_line
      description
      person_name
      person_title
      person_image {
        ...ImagePresetsFragment
      }
      primary_button_text
      secondary_button_text
      primary_button_target {
        ...ButtonTargetFragment
      }
      secondary_button_target {
        ...ButtonTargetFragment
      }
    }
  }
  ${print(ImagePresetsFragment)}
  ${print(ButtonTargetFragment)}
`;
