import { print } from "graphql";
import gql from "graphql-tag";
import { ImagePresetsFragment } from "../ImagePresetsFragment";

export const PageHeaderWithIconButtonsFragment = gql`
  fragment PageHeaderWithIconButtonsFragment on _prepr_types {
    ... on PageHeaderWithIconButtons {
      title
      subtitle
      body
      image {
        ...ImagePresetsFragment
      }
      tagline
      icon_buttons {
        icon {
          ...ImagePresetsFragment
        }
        button_target {
          ... on ExternalPage {
            url
          }
        }
      }
      circle_enabled
    }
  }
  ${print(ImagePresetsFragment)}
`;
