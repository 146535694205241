import { print } from "graphql";
import gql from "graphql-tag";
import { ImagePresetsFragment } from "../ImagePresetsFragment";

export const BigUspBlockFragment = gql`
  fragment BigUspBlockFragment on _prepr_types {
    ... on BigUspBlock {
      heading1
      heading2
      body
      image {
        ...ImagePresetsFragment
      }
      highlights {
        title
        description
        icon {
          url
          description
        }
      }
    }
  }
  ${print(ImagePresetsFragment)}
`;
