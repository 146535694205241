import Image from "next/image";
import { Grid } from "@/components/shared/Grid";
import { parseText } from "@/utilities/htmlParser";
import { GetDynamicContentItemProps } from "@/components/blocks/DynamicContentBlock/types";
import Button from "@/components/shared/Button";
import PageHeaderTwoCircle from "./PageHeaderTwo/PageHeaderTwoCircle";

export type PageHeaderWithIconButtonsProps =
  GetDynamicContentItemProps<"PageHeaderWithIconButtons">;

const PageHeaderWithIconButtons: React.FC<PageHeaderWithIconButtonsProps> = ({
  title,
  subtitle,
  body,
  image: img,
  icon_buttons,
  circle_enabled,
  className,
}) => {
  const image = img?.[0];
  return (
    <header
      data-block="PageHeaderWithIconButtons"
      className={`bg-grey-light tablet:h-[690px] ${className ?? ""}`}
    >
      <Grid className="relative z-0 h-full">
        <div className="tablet:mt-[82px] tablet:mb-32 tablet:min-h-[640px] relative col-span-6 my-12">
          {title && (
            <p className="text-p text-secondary-burgundy tablet:mb-10 tablet:text-h3-sm mb-2 line-clamp-1 font-serif tracking-wider">
              {title}
            </p>
          )}
          {subtitle && (
            <h1 className="text-h1-sm tablet:mb-10 tablet:text-h1-md line-clamp-2 font-sans font-bold tracking-wider">
              {subtitle}
            </h1>
          )}
          {body && (
            <div className="text-p tablet:mb-10 line-clamp-3 font-serif tracking-wider">
              {parseText(body)}
            </div>
          )}
          <div className="flex-0 desktop-s:mt-10 desktop-s:flex-row desktop:gap-10 my-8 flex w-auto flex-col gap-6">
            {icon_buttons?.map((icon, i) => {
              if (!icon.icon || !icon.button_target) return null;
              return (
                <Button
                  key={i}
                  type="externalLink"
                  href={icon.button_target[0]?.url ?? ""}
                  variant="secondary"
                  className="desktop-s:self-center max-h-[88px] self-start"
                  iconPosition="center"
                  icon={
                    <Image
                      key={i}
                      src={icon.icon[0]?.default ?? ""}
                      alt={icon.icon[0]?.description ?? ""}
                      layout="intrinsic"
                      objectFit="contain"
                      className="cursor-pointer"
                      width="168"
                      height="42"
                    />
                  }
                >
                  <></>
                </Button>
              );
            })}
          </div>
        </div>

        {image?.desktop && (
          <div className="tablet:block relative -z-10 col-span-5 col-start-8 hidden">
            {circle_enabled && <PageHeaderTwoCircle />}
            <div className="absolute aspect-square h-full max-h-[1080px]">
              <Image
                src={image.desktop}
                alt={image.description || "header image"}
                objectFit="cover"
                priority
                width={650}
                height={690}
              />
            </div>
          </div>
        )}
      </Grid>
      {image?.mobile && (
        <div className="tablet:hidden relative block aspect-[360/220] min-h-56">
          <Image
            src={image.mobile}
            alt={image.description || "header image"}
            layout="fill"
            objectFit="cover"
            priority
          />
        </div>
      )}
    </header>
  );
};

export default PageHeaderWithIconButtons;
