import { print } from "graphql";
import gql from "graphql-tag";
import { ImagePresetsFragment } from "../ImagePresetsFragment";

export const TwoImagesBlockFragment = gql`
  fragment TwoImagesBlockFragment on _prepr_types {
    ... on TwoImagesBlock {
      images {
        ...ImagePresetsFragment
      }
      description
    }
  }
  ${print(ImagePresetsFragment)}
`;
