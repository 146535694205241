import gql from "graphql-tag";
import { print } from "graphql";
import { ImagePresetsFragment } from "../ImagePresetsFragment";

export const IconsBlockFragment = gql`
  fragment IconsBlockFragment on _prepr_types {
    ... on IconsBlock {
      icons {
        image {
          ...ImagePresetsFragment
        }
        button_target {
          ... on ExternalPage {
            url
          }
        }
      }
    }
  }
  ${print(ImagePresetsFragment)}
`;
