import { print } from "graphql";
import gql from "graphql-tag";
import { ImagePresetsFragment } from "./ImagePresetsFragment";

export const ContactServiceBlockFragment = gql`
  fragment ContactServiceBlockFragment on _prepr_types {
    ... on ContactServiceBlock {
      title
      chat_title
      chat_text
      chat_button_text
      whatsapp_title
      whatsapp_text
      whatsapp_button_text
      email_title
      email_text
      email_button_text
      email_button2_text
      email_button2_target {
        __typename
        ... on DialogForm {
          form_type
          salesforce_entity
          name
        }
        ... on ServiceRequestForm {
          _slug
        }
        ... on DynamicPage {
          _slug
        }
        ... on StaticPage {
          _slug
        }
        ... on ExternalPage {
          url
        }
      }
      phone_title
      phone_text
      phone_button_text
      phone_number
      phone_callmeback_button_text
      employee_name
      employee_role
      employee_image {
        ...ImagePresetsFragment
      }
    }
  }
  ${print(ImagePresetsFragment)}
`;
