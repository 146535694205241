import gql from "graphql-tag";

export const SpotlightBlogBlockFragment = gql`
  fragment SpotlightBlogBlockFragment on _prepr_types {
    ... on SpotlightBlogBlock {
      intro_text
      button_text
      blog {
        _slug
        type
        card_title
        _publish_on
        image {
          ... on Asset {
            default: url(preset: "default")
            spotlight: url(preset: "spotlight")
            description
          }
        }
      }
    }
  }
`;
