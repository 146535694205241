import { useFormContext, useController } from "react-hook-form";
import { useEffect, useState } from "react";
import {
  formatCurrencyInput,
  formattedPriceToCents,
} from "@/components/pages/LeaseCalculator/utils";
import FormInput from "./FormInput";

interface Props {
  name: string;
  label: string;
  className?: string;
  disabled?: boolean;
  onBlur?: () => void;
  onChange?: () => void;
}

export const ControlledFormInputFormatCurrency: React.FC<Props> = ({
  name,
  label,
  className,
  disabled,
  onBlur,
  onChange,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const {
    field,
    formState: { isLoading },
  } = useController({
    name,
    control,
    rules: { required: true },
  });

  const placeholder = (0).toLocaleString("nl-NL", { minimumFractionDigits: 2 });
  const formattedValue = ((field?.value ?? 0) / 100).toLocaleString("nl-NL", {
    minimumFractionDigits: 2,
  });
  const [stringValue, setStringValue] = useState<string>(formattedValue);

  useEffect(() => {
    if (stringValue === undefined) return;
    field.onChange(formattedPriceToCents(stringValue));
    if (onChange) onChange();
  }, [stringValue]);

  const formatField = () => {
    setStringValue(formatCurrencyInput(stringValue));
  };

  return (
    <>
      <FormInput
        label={label}
        className={className}
        onChange={setStringValue}
        onBlur={() => {
          field.onBlur();
          formatField();
          if (onBlur) onBlur();
        }}
        value={stringValue || ""}
        name={field.name}
        placeholder={placeholder}
        inputRef={field.ref}
        disabled={isLoading || disabled}
        error={errors[name]?.message ? <>{errors[name]?.message}</> : ""}
        inputPrefix={<span>&euro;</span>}
      />
    </>
  );
};
