import { print } from "graphql";
import gql from "graphql-tag";
import { ImagePresetsFragment } from "../ImagePresetsFragment";

export const SubjectOverviewBlockFragment = gql`
  fragment SubjectOverviewBlockFragment on _prepr_types {
    ... on SubjectOverviewBlock {
      title
      subtitle
      body
      cards {
        title
        body
        target {
          __typename
          ... on DialogForm {
            form_type
            salesforce_entity
            name
          }
          ... on ServiceRequestForm {
            _slug
          }
          ... on DynamicPage {
            _slug
          }
          ... on StaticPage {
            _slug
          }
          ... on ExternalPage {
            url
          }
        }
        image {
          ...ImagePresetsFragment
        }
      }
    }
  }
  ${print(ImagePresetsFragment)}
`;
