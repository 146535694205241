import { createContext, useContext, useEffect, useState } from "react";

type ErrorState = Record<string, boolean>;
interface LeaseCalculatorStateContextProps {
  hasApiError: boolean;
  isApiLoading: boolean;
  // TODO: not sure how to make this non-optional with a default value
  setApiErrorState?: React.Dispatch<React.SetStateAction<ErrorState>>;
  setApiLoadingState?: React.Dispatch<React.SetStateAction<ErrorState>>;
}
export const LeaseCalculatorStateContext = createContext<LeaseCalculatorStateContextProps>({
  hasApiError: false,
  isApiLoading: false,
});

export const LeaseCalculatorStateProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [apiErrorState, setApiErrorState] = useState<ErrorState>({});
  const [apiLoadingState, setApiLoadingState] = useState<ErrorState>({});

  const hasApiError = Object.values(apiErrorState).findIndex((item) => item) !== -1;
  const isApiLoading = Object.values(apiLoadingState).findIndex((item) => item) !== -1;

  return (
    <LeaseCalculatorStateContext.Provider
      value={{ hasApiError, setApiErrorState, setApiLoadingState, isApiLoading }}
    >
      {children}
    </LeaseCalculatorStateContext.Provider>
  );
};

export const useAddApiError = (name: string, value: boolean) => {
  const { setApiErrorState } = useContext(LeaseCalculatorStateContext);
  const errorPair: Record<string, boolean> = {};
  errorPair[name] = value;
  useEffect(() => {
    setApiErrorState && setApiErrorState((prev) => ({ ...prev, ...errorPair }));
  }, [value]);
};

export const useAddApiLoading = (name: string, value: boolean) => {
  const { setApiLoadingState } = useContext(LeaseCalculatorStateContext);
  const errorPair: Record<string, boolean> = {};
  errorPair[name] = value;
  useEffect(() => {
    setApiLoadingState && setApiLoadingState((prev) => ({ ...prev, ...errorPair }));
  }, [value]);
};
