import { print } from "graphql";
import gql from "graphql-tag";
import { ImagePresetsFragment } from "../ImagePresetsFragment";
import { ButtonTargetFragment } from "./ButtonTargetFragment";

export const CallToActionBlockFragment = gql`
  fragment CallToActionBlockFragment on _prepr_types {
    ... on CallToActionBlock {
      text
      image {
        ctaRectangle: url(preset: "CTA Rectangle")
        url: url
        description
      }
      variant
      button_text
      button_target {
        ...ButtonTargetFragment
      }
    }
  }
  ${print(ImagePresetsFragment)}
  ${print(ButtonTargetFragment)}
`;
