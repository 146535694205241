import Link from "next/link";
import React from "react";
import { useButtonTarget } from "@/hooks/useButtonTarget";
import { SubjectOverviewTarget } from "../SubjectOverviewBlock";
import CardContent from "./SubjectCard/SubjectCardContent";

interface SubjectCardProps {
  isHovered: boolean;
  setIsHovered: {
    onMouseEnter: React.MouseEventHandler<any>;
    onMouseLeave: React.MouseEventHandler<any>;
  };
  title: string;
  body: string;
  target?: SubjectOverviewTarget;
  className?: string;
  style?: React.CSSProperties;
}

const SubjectCard: React.FC<SubjectCardProps> = ({
  isHovered,
  setIsHovered,
  title,
  body,
  target,
  className,
  style,
}) => {
  const { type, onClick, href, isValid } = useButtonTarget(target);
  if (type === "button") {
    return (
      <button
        {...setIsHovered}
        className={`text-left ${onClick ? "pointer-events-auto" : "pointer-events-none"} ${
          className ?? ""
        }`}
        style={style}
        onClick={onClick}
      >
        <CardContent title={title} body={body} isHovered={isHovered} hasLink={isValid} />
      </button>
    );
  }
  if (type === "link") {
    return (
      <Link href={href || "/404"}>
        <a
          {...setIsHovered}
          className={`${href ? "pointer-events-auto" : "pointer-events-none"} ${className ?? ""}`}
          style={style}
        >
          <CardContent title={title} body={body} isHovered={isHovered} hasLink={isValid} />
        </a>
      </Link>
    );
  }
  if (type === "externalLink") {
    return (
      <a
        {...setIsHovered}
        className={`${href ? "pointer-events-auto" : "pointer-events-none"} ${className ?? ""}`}
        style={style}
        href={href?.startsWith("http") ? href : `//${href}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <CardContent title={title} body={body} isHovered={isHovered} hasLink={isValid} />
      </a>
    );
  }
  return null;
};

export default SubjectCard;
