import { print } from "graphql";
import gql from "graphql-tag";
import { ImagePresetsFragment } from "../ImagePresetsFragment";

export const PageHeaderThreeFragment = gql`
  fragment PageHeaderThreeFragment on _prepr_types {
    ... on PageHeaderThree {
      title
      subtitle
      body
      tagline
      image {
        ...ImagePresetsFragment
      }
    }
  }
  ${print(ImagePresetsFragment)}
`;
