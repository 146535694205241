import { print } from "graphql";
import gql from "graphql-tag";
import { ButtonTargetFragment } from "./DynamicContentFragment/ButtonTargetFragment";

export const CtaAboveHeaderFragment = gql`
  fragment CtaAboveHeaderFragment on _prepr_types {
    ... on CtaAboveHeader {
      text
      button_text
      button_target {
        ...ButtonTargetFragment
      }
      button_text_2
      button_target_2 {
        ...ButtonTargetFragment
      }
    }
  }
  ${print(ButtonTargetFragment)}
`;
