import gql from "graphql-tag";
import { print } from "graphql/language/printer";
import { TeamBlockFragment } from "@/services/prepr/fragments/DynamicContentFragment/TeamBlockFragment";
import { PageHeaderTwoFragment } from "./DynamicContentFragment/PageHeaderTwoFragment";
import { PageHeaderWithIconButtonsFragment } from "./DynamicContentFragment/PageHeaderWithIconButtonsFragment";
import { PageHeaderThreeFragment } from "./DynamicContentFragment/PageHeaderThreeFragment";
import { ParagraphBlockFragment } from "./DynamicContentFragment/ParagraphBlockFragment";
import { HeadingBlockFragment } from "./DynamicContentFragment/HeadingBlockFragment";
import { OneImageBlockFragment } from "./DynamicContentFragment/OneImageBlockFragment";
import { ThreeImagesBlockFragment } from "./DynamicContentFragment/ThreeImagesBlockFragment";
import { TwoImagesBlockFragment } from "./DynamicContentFragment/TwoImagesBlockFragment";
import { QuoteBlockFragment } from "./DynamicContentFragment/QuoteBlockFragment";
import { ButtonBlockFragment } from "./DynamicContentFragment/ButtonBlockFragment";
import { HighlightsBlockFragment } from "./DynamicContentFragment/HighlightsBlockFragment";
import { PageLinksBlockFragment } from "./DynamicContentFragment/PageLinksBlockFragment";
import { CustomerExperienceEntryBlockFragment } from "./DynamicContentFragment/CustomerExperienceEntryBlockFragment";
import { MoreCustomerExperiencesEntryBlockFragment } from "./DynamicContentFragment/MoreCustomerExperiencesEntryBlockFragment";
import { SubjectOverviewBlockFragment } from "./DynamicContentFragment/SubjectOverviewBlockFragment";
import { FaqBlockFragment } from "./DynamicContentFragment/FaqBlockFragment";
import { ContactPersonBlockFragment } from "./DynamicContentFragment/ContactPersonBlockFragment";
import { CallToActionBlockFragment } from "./DynamicContentFragment/CallToActionBlockFragment";
import { CallToActionBlock2Fragment } from "./DynamicContentFragment/CallToActionBlock2Fragment";
import { BlogsEntryBlockFragment } from "./DynamicContentFragment/BlogsEntryBlockFragment";
import { SpotlightBlogBlockFragment } from "./DynamicContentFragment/SpotlightBlogBlockFragment";
import { InformationTabsBlockFragment } from "./DynamicContentFragment/InformationTabsBlockFragment";
import { SubHeaderLinksBlockFragment } from "./DynamicContentFragment/SubHeaderLinksBlockFragment";
import { CustomerExperienceCarouselFragment } from "./DynamicContentFragment/CustomerExperienceCarouselFragment";
import { EmployeeExperienceCarouselFragment } from "./DynamicContentFragment/EmployeeExperienceCarouselFragment";
import { BigUspBlockFragment } from "./DynamicContentFragment/BigUspBlockFragment";
import { WhitepaperRequestBlockFragment } from "./DynamicContentFragment/WhitepaperRequestBlockFragment";
import { StepByStepBlockFragment } from "./DynamicContentFragment/StepByStepBlockFragment";
import { PageHeaderFragment } from "./DynamicContentFragment/PageHeaderFragment";
import { CtaAboveHeaderFragment } from "./CtaAboveHeaderFragment";
import { IconsBlockFragment } from "./DynamicContentFragment/IconsBlockFragment";
import { LeaseCalculatorCtaFragment } from "./DynamicContentFragment/LeaseCalculatorCtaFragment";
import { ContactServiceBlockFragment } from "./ContactServiceBlockFragment";
import { FaqOverviewBlockFragment } from "./DynamicContentFragment/FaqOverviewBlockFragment";
import { FaqSingleSubjectFragment } from "./DynamicContentFragment/FaqSingleSubjectFragment";
import { SubHeaderLinkCardsBlockFragment } from "./DynamicContentFragment/SubHeaderLinkCardsBlockFragment";
import { ScriptBlockFragment } from "./DynamicContentFragment/ScriptBlockFragment";
import { HelloRiderBlockFragment } from "./DynamicContentFragment/HelloRiderBlockFragment";

// NOTE: DynamicContentFragment is too large to fit in prepr edge cache
// https://docs.prepr.io/reference/graphql/v1/caching/
// Therefore we split the query into Part1 Part2 Part3 and join the results.

// TODO: prepr tells us that this edge cache limit is no longer an issue

export const DynamicContentFragment = gql`
  fragment DynamicContentFragment on _prepr_types {
    ...PageHeaderFragment
    ...PageHeaderTwoFragment
    ...PageHeaderWithIconButtonsFragment
    ...PageHeaderThreeFragment
    ...ParagraphBlockFragment
    ...HeadingBlockFragment
    ...OneImageBlockFragment
    ...TwoImagesBlockFragment
    ...ThreeImagesBlockFragment
    ...QuoteBlockFragment
    ...ButtonBlockFragment
    ...HighlightsBlockFragment
    ...PageLinksBlockFragment
    ...CustomerExperienceEntryBlockFragment
    ...MoreCustomerExperiencesEntryBlockFragment
    ...SubjectOverviewBlockFragment
    ...BigUspBlockFragment
    ...FaqBlockFragment
    ...ContactPersonBlockFragment
    ...ContactServiceBlockFragment
    ...CallToActionBlockFragment
    ...CallToActionBlock2Fragment
    ...TeamBlockFragment
    ...BlogsEntryBlockFragment
    ...SpotlightBlogBlockFragment
    ...InformationTabsBlockFragment
    ...SubHeaderLinksBlockFragment
    ...CustomerExperienceCarouselFragment
    ...EmployeeExperienceCarouselFragment
    ...WhitepaperRequestBlockFragment
    ...StepByStepBlockFragment
    ...CtaAboveHeaderFragment
    ...IconsBlockFragment
    ...LeaseCalculatorCtaFragment
    ...FaqOverviewBlockFragment
    ...FaqSingleSubjectFragment
    ...SubHeaderLinkCardsBlockFragment
    ...ScriptBlockFragment
    ...HelloRiderBlockFragment
  }
  ${print(PageHeaderFragment)}
  ${print(PageHeaderTwoFragment)}
  ${print(PageHeaderWithIconButtonsFragment)}
  ${print(PageHeaderThreeFragment)}
  ${print(ParagraphBlockFragment)}
  ${print(HeadingBlockFragment)}
  ${print(OneImageBlockFragment)}
  ${print(TwoImagesBlockFragment)}
  ${print(ThreeImagesBlockFragment)}
  ${print(QuoteBlockFragment)}
  ${print(ButtonBlockFragment)}
  ${print(HighlightsBlockFragment)}
  ${print(PageLinksBlockFragment)}
  ${print(CustomerExperienceEntryBlockFragment)}
  ${print(MoreCustomerExperiencesEntryBlockFragment)}
  ${print(SubjectOverviewBlockFragment)}
  ${print(BigUspBlockFragment)}
  ${print(FaqBlockFragment)}
  ${print(ContactPersonBlockFragment)}
  ${print(ContactServiceBlockFragment)}
  ${print(CallToActionBlockFragment)}
  ${print(CallToActionBlock2Fragment)}
  ${print(TeamBlockFragment)}
  ${print(BlogsEntryBlockFragment)}
  ${print(SpotlightBlogBlockFragment)}
  ${print(InformationTabsBlockFragment)}
  ${print(SubHeaderLinksBlockFragment)}
  ${print(CustomerExperienceCarouselFragment)}
  ${print(EmployeeExperienceCarouselFragment)}
  ${print(WhitepaperRequestBlockFragment)}
  ${print(StepByStepBlockFragment)}
  ${print(CtaAboveHeaderFragment)}
  ${print(IconsBlockFragment)}
  ${print(LeaseCalculatorCtaFragment)}
  ${print(FaqOverviewBlockFragment)}
  ${print(FaqSingleSubjectFragment)}
  ${print(SubHeaderLinkCardsBlockFragment)}
  ${print(ScriptBlockFragment)}
  ${print(HelloRiderBlockFragment)}
`;

export const DynamicContentFragmentPart1 = gql`
  fragment DynamicContentFragmentPart1 on _prepr_types {
    ...PageHeaderTwoFragment
    ...PageHeaderWithIconButtonsFragment
    ...PageHeaderThreeFragment
    ...ParagraphBlockFragment
    ...HeadingBlockFragment
    ...OneImageBlockFragment
    ...TwoImagesBlockFragment
    ...ThreeImagesBlockFragment
    ...QuoteBlockFragment
    ...HighlightsBlockFragment
    ...PageLinksBlockFragment
    ...CustomerExperienceEntryBlockFragment
  }
  ${print(PageHeaderTwoFragment)}
  ${print(PageHeaderWithIconButtonsFragment)}
  ${print(PageHeaderThreeFragment)}
  ${print(ParagraphBlockFragment)}
  ${print(HeadingBlockFragment)}
  ${print(OneImageBlockFragment)}
  ${print(TwoImagesBlockFragment)}
  ${print(ThreeImagesBlockFragment)}
  ${print(QuoteBlockFragment)}
  ${print(HighlightsBlockFragment)}
  ${print(PageLinksBlockFragment)}
  ${print(CustomerExperienceEntryBlockFragment)}
`;

export const DynamicContentFragmentPart2 = gql`
  fragment DynamicContentFragmentPart2 on _prepr_types {
    ...FaqBlockFragment
    ...ButtonBlockFragment
    ...ContactPersonBlockFragment
    ...ContactServiceBlockFragment
    ...SubjectOverviewBlockFragment
    ...BigUspBlockFragment
    ...WhitepaperRequestBlockFragment
    ...StepByStepBlockFragment
    ...EmployeeExperienceCarouselFragment
  }
  ${print(FaqBlockFragment)}
  ${print(ButtonBlockFragment)}
  ${print(ContactPersonBlockFragment)}
  ${print(ContactServiceBlockFragment)}
  ${print(SubjectOverviewBlockFragment)}
  ${print(BigUspBlockFragment)}
  ${print(WhitepaperRequestBlockFragment)}
  ${print(StepByStepBlockFragment)}
  ${print(EmployeeExperienceCarouselFragment)}
`;

export const DynamicContentFragmentPart3 = gql`
  fragment DynamicContentFragmentPart3 on _prepr_types {
    ...PageHeaderFragment
    ...CtaAboveHeaderFragment
    ...CallToActionBlockFragment
    ...CallToActionBlock2Fragment
    ...TeamBlockFragment
    ...BlogsEntryBlockFragment
    ...FaqSingleSubjectFragment
    ...SubHeaderLinkCardsBlockFragment
    ...ScriptBlockFragment
  }
  ${print(PageHeaderFragment)}
  ${print(CtaAboveHeaderFragment)}
  ${print(CallToActionBlockFragment)}
  ${print(CallToActionBlock2Fragment)}
  ${print(TeamBlockFragment)}
  ${print(BlogsEntryBlockFragment)}
  ${print(FaqSingleSubjectFragment)}
  ${print(SubHeaderLinkCardsBlockFragment)}
  ${print(ScriptBlockFragment)}
`;

export const DynamicContentFragmentPart4 = gql`
  fragment DynamicContentFragmentPart4 on _prepr_types {
    ...MoreCustomerExperiencesEntryBlockFragment
    ...SpotlightBlogBlockFragment
    ...InformationTabsBlockFragment
    ...SubHeaderLinksBlockFragment
    ...CustomerExperienceCarouselFragment
    ...IconsBlockFragment
    ...LeaseCalculatorCtaFragment
    ...FaqOverviewBlockFragment
    ...HelloRiderBlockFragment
  }
  ${print(MoreCustomerExperiencesEntryBlockFragment)}
  ${print(SpotlightBlogBlockFragment)}
  ${print(InformationTabsBlockFragment)}
  ${print(SubHeaderLinksBlockFragment)}
  ${print(CustomerExperienceCarouselFragment)}
  ${print(IconsBlockFragment)}
  ${print(LeaseCalculatorCtaFragment)}
  ${print(FaqOverviewBlockFragment)}
  ${print(HelloRiderBlockFragment)}
`;
