import React from "react";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import BlogCard from "@/components/shared/BlogCard";
import { parseQuote } from "@/utilities/htmlParser";
import { CustomerExperienceCarouselProps } from "../CustomerExperienceCarousel";

interface CustomerExperienceCarouselMobileProps {
  customerStories: CustomerExperienceCarouselProps["customer_stories"];
  cardButtonText: CustomerExperienceCarouselProps["card_button_text"];
}

const CustomerExperienceCarouselMobile: React.FC<CustomerExperienceCarouselMobileProps> = ({
  customerStories,
  cardButtonText,
}) => {
  const initialSlide = customerStories?.length ? Math.ceil(customerStories.length / 2) - 1 : 0;

  return (
    <Swiper
      slidesPerView="auto"
      spaceBetween={16}
      pagination={{
        el: ".swiper-pagination",
        clickable: false,
        type: "bullets",
        bulletClass: "w-2 h-2 bg-white rounded-full opacity-100 cursor-pointer",
        bulletActiveClass: "!w-3 !h-3 !bg-secondary-burgundy",
      }}
      modules={[Pagination]}
      centeredSlides
      initialSlide={initialSlide}
      className="mt-10 !pb-9"
    >
      {customerStories?.map(({ quote: customQuote, items }, index) => {
        const customerStory = items?.[0];
        const { _slug, caption, image, quote: defaultQuote } = customerStory ?? {};
        const img = image?.[0]?.default;
        const quote = customQuote ?? defaultQuote;
        if (!_slug || !caption || !quote || !img) return null;
        return (
          <SwiperSlide key={index} className="!flex !h-auto !w-[82%]">
            <BlogCard
              link={_slug}
              caption={
                <p className="text-p-xs tablet:text-p font-serif tracking-wider">{caption}</p>
              }
              title={parseQuote(quote)}
              image={img}
              buttonText={cardButtonText}
              darkThemeEnabled
            />
          </SwiperSlide>
        );
      })}
      <div className="swiper-pagination !bottom-0 mt-6 !flex !flex-1 !items-center !justify-center !gap-3" />
    </Swiper>
  );
};

export default CustomerExperienceCarouselMobile;
