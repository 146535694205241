import gql from "graphql-tag";

export const FaqSingleSubjectFragment = gql`
  fragment FaqSingleSubjectFragment on _prepr_types {
    ... on FaqSingleSubjectBlock {
      subject {
        # ... on FaqTopic {
        #   _id
        #   name
        # }
        ... on FaqSubject {
          _id
          faq_items {
            _id
            question
            answer
            button_text
            button_target {
              __typename
              ... on DialogForm {
                form_type
                salesforce_entity
                name
              }
              ... on ServiceRequestForm {
                _slug
              }
              ... on DynamicPage {
                _slug
              }
              ... on StaticPage {
                _slug
              }
              ... on ExternalPage {
                url
              }
            }
          }
        }
      }
    }
  }
`;
