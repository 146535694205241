import React from "react";
import Link from "next/link";
import BlogCardContent from "./BlogCard/BlogCardContent";
import PreviewCard from "./PreviewCard/PreviewCard";

export interface BlogCardProps {
  caption?: React.ReactNode;
  title?: React.ReactNode;
  image?: string;
  buttonText?: string | null;
  link: string;
  darkThemeEnabled?: boolean;
  linkDisabled?: boolean;
  className?: string;
}

const BlogCard: React.FC<BlogCardProps> = ({
  caption,
  title,
  image,
  buttonText,
  link,
  darkThemeEnabled,
  linkDisabled,
  className,
}) => {
  if (linkDisabled) {
    return (
      <PreviewCard darkThemeEnabled={darkThemeEnabled}>
        <BlogCardContent
          caption={caption}
          title={title}
          image={image}
          buttonText={buttonText}
          darkThemeEnabled={darkThemeEnabled}
          linkDisabled={linkDisabled}
        />
      </PreviewCard>
    );
  }
  return (
    <Link href={link}>
      <a
        className={`relative z-10 col-span-4 flex flex-1 flex-col ${
          darkThemeEnabled ? "text-white" : "text-primary-black"
        } ${className ?? ""}`}
      >
        <PreviewCard darkThemeEnabled={darkThemeEnabled}>
          <BlogCardContent
            caption={caption}
            title={title}
            image={image}
            buttonText={buttonText}
            darkThemeEnabled={darkThemeEnabled}
            linkDisabled={linkDisabled}
          />
        </PreviewCard>
      </a>
    </Link>
  );
};

export default BlogCard;
