import { print } from "graphql";
import gql from "graphql-tag";
import { ImagePresetsFragment } from "../ImagePresetsFragment";
import { ButtonTargetFragment } from "./ButtonTargetFragment";

export const LeaseCalculatorCtaFragment = gql`
  fragment LeaseCalculatorCtaFragment on _prepr_types {
    ... on LeaseCalculatorCta {
      title
      subtitle
      tagline
      tagline_mobile
      leaseprice_label
      button_text
      image {
        ...ImagePresetsFragment
      }
      te_financieren_bedrag_te_hoog
      te_financieren_bedrag_te_laag
      not_available_title
      not_available_subtitle
      not_available_description
      not_available_button_1_tel
      not_available_button_2_text
      not_available_button_2_target {
        ...ButtonTargetFragment
      }
    }
  }
  ${print(ImagePresetsFragment)}
  ${print(ButtonTargetFragment)}
`;
