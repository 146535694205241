import gql from "graphql-tag";

export const ButtonTargetFragment = gql`
  fragment ButtonTargetFragment on AllModels {
    __typename
    ... on AnchorLink {
      _slug
    }
    ... on BlogOverviewPage {
      _slug
    }
    ... on BlogPage {
      _slug
    }
    ... on ContactPage {
      _slug
    }
    ... on CustomerStoryOverviewPage {
      _slug
    }
    ... on CustomerStoryPage {
      _slug
    }
    ... on DialogForm {
      form_type
      salesforce_entity
      name
    }
    ... on DynamicPage {
      _slug
    }
    ... on EmployeeStoryOverviewPage {
      _slug
    }
    ... on EmployeeStoryPage {
      _slug
    }
    ... on ExternalPage {
      url
    }
    ... on FaqPage {
      _slug
    }
    ... on RequestAppointmentForm {
      title
      introtext
      image {
        ...ImagePresetsFragment
      }
      confirmationtext
      campaigntag
    }
    ... on ServicePage {
      _slug
    }
    ... on ServiceRequestForm {
      _slug
    }
    ... on ShortLeaseSupplyPage {
      _slug
    }
    ... on ShortleaseCarDetailsPage {
      _slug
    }
    ... on StaticPage {
      _slug
    }
    ... on SupplyPage {
      _slug
    }
    ... on WhitepaperOverviewPage {
      _slug
    }
  }
`;
