import { useFormContext, useController } from "react-hook-form";
import SelectList from "../SelectList";

interface Props {
  name: string;
  label: string;
  className?: string;
  labelClassName?: string;
  placeholder?: string;
  options: Record<string, string>;
  order?: "unordered" | "ascending" | "descending" | "key";
  disabled?: boolean;
  onChange?: (v: string | number) => void;
  type?: "number" | "string";
}
export const ControlledSelectList: React.FC<Props> = ({
  name,
  label,
  className,
  labelClassName,
  placeholder,
  options,
  disabled,
  onChange,
  order,
  type = "string",
}) => {
  const {
    control,
    trigger,
    formState: { errors },
  } = useFormContext();
  const {
    field,
    formState: { isLoading },
  } = useController({
    name,
    control,
    rules: { required: true },
  });
  const wrapOnChange = (v: string) => {
    const value = type === "number" ? Number(v) : v;
    field.onChange(value);
    if (onChange) onChange(value);
    trigger(name);
  };
  return (
    <SelectList
      label={label}
      className={className}
      labelClassName={labelClassName}
      placeholder={placeholder}
      options={options}
      onChange={wrapOnChange}
      onBlur={field.onBlur}
      selectedOption={field.value}
      name={field.name}
      order={order}
      inputRef={field.ref}
      disabled={isLoading || disabled}
      error={errors[name]?.message ? <>{errors[name]?.message}</> : ""}
    />
  );
};
