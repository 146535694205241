import gql from "graphql-tag";

export const SubHeaderLinksBlockFragment = gql`
  fragment SubHeaderLinksBlockFragment on _prepr_types {
    ... on SubHeaderLinksBlock {
      heading
      links {
        text
        target {
          __typename
          ... on DialogForm {
            form_type
            salesforce_entity
            name
          }
          ... on ServiceRequestForm {
            _slug
          }
          ... on DynamicPage {
            _slug
          }
          ... on StaticPage {
            _slug
          }
          ... on ExternalPage {
            url
          }
          ... on ServiceCategory {
            name
          }
          ... on ServiceSubcategory {
            name
            category {
              name
            }
          }
          ... on AnchorLink {
            link
          }
        }
      }
    }
  }
`;
