import { ObjectsAndGroups } from "@/services/lease-calculator/api-calls";
import { monthOptions } from "./ObjectGegevens/ObjectGegevens";

export const formatMonth = (value?: string) => (value ? monthOptions[Number(value)] : "-");
export const formatLicensePlate = (value?: string) => value?.toUpperCase().replaceAll("-", " - ");
export const formatMileage = (value?: string) => `${value ? value : "-"} km`;

export const getObjectGroupName = (objectsAndGroups: ObjectsAndGroups, objectGroupId: number) =>
  objectsAndGroups.objectGroups.find((g) => g.objectGroupId === objectGroupId)?.name;
export const getObjectName = (objectsAndGroups: ObjectsAndGroups, objectGroupId: number) =>
  objectsAndGroups.objects.find((g) => g.objectId === objectGroupId)?.name;

export const formatPrice = (price: number): string => {
  const formattedPrice = Intl.NumberFormat("nl-NL", {
    style: "currency",
    currency: "EUR",
  }).format(price / 100);
  if (price && price % 100 === 0) return formattedPrice.slice(0, -3) + ",-";
  return price ? formattedPrice : "€";
};

export const formattedPriceToCents = (stringValue: string): number | undefined => {
  // FIXME: consider refactoring this function to regex
  const fixedString =
    stringValue.length > 0 && stringValue.lastIndexOf(",") === stringValue.length - 1
      ? stringValue + "00"
      : stringValue.length > 1 && stringValue.lastIndexOf(",") === stringValue.length - 2
      ? stringValue + "0"
      : stringValue;
  const amountInCents =
    fixedString === "0,00"
      ? "0"
      : fixedString?.includes(",")
      ? (fixedString.split(",")[0] + fixedString.split(",")[1].slice(0, 2)).replaceAll(".", "")
      : fixedString.replaceAll(".", "") + "00";

  return !isNaN(Number(amountInCents)) ? Number(amountInCents) : undefined;
};

export const formatCurrencyInput = (stringValue: string): string => {
  const value = Number(stringValue?.replaceAll(".", "").replace(",", "."));
  return !isNaN(value)
    ? value.toLocaleString("nl-NL", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    : stringValue;
};

export const monthDiff = (yearFrom: number, monthFrom: number, yearTo: number, monthTo: number) =>
  monthTo - monthFrom + 12 * (yearTo - yearFrom);

export const filterMonthOption = (
  month: number,
  constructionYear: number,
  duration: number,
  leaseStartMaxAge: number,
  leaseEndMaxAge: number
): boolean => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  if (!constructionYear) return true;
  if (constructionYear > currentYear) {
    return month <= currentMonth;
  }
  if (monthDiff(constructionYear, month, currentYear, currentMonth) > leaseStartMaxAge)
    return false;
  if (monthDiff(constructionYear, month, currentYear, currentMonth) + duration > leaseEndMaxAge)
    return false;
  return true;
};
