import React from "react";
import { Grid } from "@/components/shared/Grid";
import { GetDynamicContentItemProps } from "@/components/blocks/DynamicContentBlock/types";

type HeadingBlockProps = GetDynamicContentItemProps<"HeadingBlock">;

const generateAnchorId = (text: string): string => {
  return "heading-" + text.toLowerCase().replace(/[^a-z0-9]+/g, "-");
};

const HeadingBlock: React.FC<HeadingBlockProps> = ({ text, type, className }) => {
  if (!text) return null;
  return (
    <Grid dataBlock="HeadingBlock" className={`relative ${className ?? ""}`}>
      <div
        id={generateAnchorId(text)}
        className="tablet:col-span-8 tablet:col-start-3 col-span-full text-black"
      >
        {type === "heading-1" && (
          <h2 className="text-h2-sm tablet:text-h3 font-sans font-bold tracking-wider">{text}</h2>
        )}
        {type === "heading-2" && (
          <h3 className="text-h3-sm tablet:text-h2-sm font-sans font-bold tracking-wider">
            {text}
          </h3>
        )}
      </div>
    </Grid>
  );
};

export default HeadingBlock;
