import gql from "graphql-tag";
import { print } from "graphql/language/printer";
import { Path } from "@/utilities/types";
import { runQuery } from "@/services/prepr/sdk";
import { DynamicContentFragment } from "../fragments/DynamicContentFragment";
import { ButtonTargetFragment } from "../fragments/DynamicContentFragment/ButtonTargetFragment";
import { GetLeaseCalculatorPageQuery } from "./getLeaseCalculatorPage.generated";

export type PreprLeaseCalculatorPageProps = NonNullable<
  Path<GetLeaseCalculatorPageQuery, ["LeaseCalculator"]>
>;

const query = gql`
  query GetLeaseCalculatorPage {
    LeaseCalculator {
      title
      meta_description
      header_title
      header_description
      customer_service_title
      customer_service_body
      step1label
      objectgegevens_label
      objectgegevens_description
      financiele_gegevens_label
      financiele_gegevens_description
      minimum_amount_message
      maximum_amount_message
      button_phone_number
      button2_text
      button2_target {
        ...ButtonTargetFragment
      }
      berekening_label
      button_step1_proceed
      step2label
      jouw_gegevens_label
      jouw_gegevens_description
      bijlagen_label
      bijlagen_description
      opmerkingen_label
      opmerkingen_description
      button_step2_proceed
      step3label
      button_step3_proceed
      thankyou {
        __typename
        ...DynamicContentFragment
      }
    }
  }
  ${print(ButtonTargetFragment)}
  ${print(DynamicContentFragment)}
`;

export const getLeaseCalculatorPage = async () => {
  const response = await runQuery<GetLeaseCalculatorPageQuery>(query);
  return response.LeaseCalculator;
};
